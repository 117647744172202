* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Changa One", Helvetica !important;
  /* background-color: #f78a38; */
  background-color: #F1762D;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  width: 100vw;
  margin: 0rem auto;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

.box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  position: relative;
  overflow-x: hidden; 
  overflow-y: hidden; 
}

.box .desktop-wrapper {
  border: 0px none;
  height: 100vh;
  width: 100vw;
}

.box .desktop {
  left: 0;
  top: 0;
}

.logo-box {
  background-color: #F1762D;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  margin-top: 3vh;
}

.logo-box .logo-wrapper {
  z-index: 1;
  border: 0px none;
  height: 16.5vh;
  width: 50vw;
}

.logo-box .logo {
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  z-index: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.main-text-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
}

.main-text-box .main-text-wrapper {
  border: 0px none;
  position: relative;
  background-color: transparent;
  height: 38vh;
  width: 42vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
}

.main-text-box .dancing-gorilla {
  height: 50%;
  width: 42vw;
  bottom: 32%;
  position: absolute;
  opacity: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}

.main-text-box .main-text {
  color: #ffb400;
  background-color: transparent;
  font-family: "Changa One-Regular", Helvetica;
  font-size: 10vh;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  width: 100%;
  text-align: left;
  word-wrap: break-word;
}

.main-text-box .sub-text-wrapper {
  background-color: transparent;
  margin-top: 2%;
  display: flex;
  float: right;
}

.main-text-box .sub-text {
  background-color: transparent;
  color: #ffb400;
  font-family: "Changa One-Regular", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
  top: 0;
  width: 35vw;
}

.waitlist-box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
}

.waitlist-box .waitlist-button {
  border: 0px none;
  height: 140px;
  width: 42%;
  background-color: #ffb400;
  border-radius: 33px;
  position: relative;

  -webkit-text-stroke: 4px #f78a38;
  color: #ffffff;
  font-family: "Changa One-Regular", Helvetica;
  font-size: 75px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;

  transition: 0.5s;
  transition-property: box-shadow;
  background: rgb(255, 180, 0);
  box-shadow: 0 0 25px rgb(255, 255, 255);
}

.waitlist-box .waitlist-button:hover {
  box-shadow: 0 0 5px rgb(255, 255, 255),
              0 0 25px rgb(255, 255, 255),
              0 0 50px rgb(255, 255, 255),
}

.popup-box {
  background-color: transparent;
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.popup-background {
  position: fixed;
  display: flex;
  background-color: black;
  opacity: 70%;
  height: 100vh;
  width: 100vw;
  z-index: 2;
}

.popup {
  position: relative;
  display: flex;
  top: 18vh;
  width: 35vw;
  height: 60vh;
  background-color: #F1762D;
  justify-content: center;
  align-items: center;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
}

.popup-inner {
  position: relative;
  height: 55vh;
  width: 35vw;
  background-color: transparent;
}

.popup-inner .close-button {
  position: absolute;
  display: flex;
  right: 1vw;
  font-size: 2.5vw;
  background-color: transparent;
  color: white;
  border: 0;
  z-index: 3;
  top: -2vh;
}

.close-button:hover {
  color:  #cb5008;
  cursor: pointer;
}

.mail-icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: transparent;
  z-index: 2;
}

.mail-icon-wrapper .mail-icon {
  width: 8vw;
}

.popup-wrapper {
  width: 30vw;
  margin-left: auto;
  margin-right: auto;
}

.popup-wrapper .popup-title-text {
  font-size: 4vw;
  color: #ffb400;
  text-align: center;
}

.popup-wrapper .popup-sub-text {
  font-size: 1.75vw;
  color: #ffb400;
  text-align: center;
  margin-top: 2vh;
  margin-right:auto;
  margin-left:auto;

}

.subscribe-button-wrapper {
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 0px none;
  margin-left: auto;
  margin-right: auto;
}

.email-wrapper {
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 0px none;
  margin-left: auto;
  margin-right: auto;
}

.email {
  background-color: #ffffff;
  border: 3px solid;
  border-color: #ffb400;
  border-radius: 10px;
  width: 30vw;
  height: 6vh;
  margin-top: 3vh;
  text-align: center;
  font-size: 4vh;
}

::placeholder {
  color: #f78a38;
  opacity: 50%;
}

input:focus {
  outline: 0;
}

input, select, textarea{
  color: #f78a38;
}

.subscribe-button {
  border: 0px none;
  background-color: #ffb400;
  border-color: #ffb400;
  border-radius: 10px;
  position: relative;
  width: 30vw;
  height: 6vh;

  -webkit-text-stroke: 3px #f78a38;
  color: #ffffff;
  font-family: "Changa One-Regular", Helvetica;
  font-size: 4vh;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;

  transition: 0.5s;
  transition-property: box-shadow;
  background: rgb(255, 180, 0);
  box-shadow: 0 0 10px rgb(255, 255, 255);

  margin-top: 2vh;
}

.subscribe-button:hover {
  box-shadow: 0 0 5px rgb(255, 255, 255),
              0 0 25px rgb(255, 255, 255),
              0 0 30px rgb(255, 255, 255),
}

.subscribed-text {
  text-align: center;
  position: relative;
  display: none;
  color: #ffb400;
  font-size: 5vw;
  top: -15vh;
}

.social-media-badges-box {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 1;
  position: relative;
}

.social-media-badges-wrapper {
  background-color: transparent;
  border: 0px none;
  height: 100px;
  width: 35vw;
  position: relative;
  margin-top: 3vh;
  display: flex; 
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
}

.tiktok-button {
  height: 100%;
  float: left;
  background-color: transparent;
}
.tiktok-button:hover {
  border-radius: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(255, 255, 255),
}

.instagram-button {
  height: 100%;
  margin: 0 auto;
  background-color: transparent;
}
.instagram-button:hover {
  border-radius: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(255, 255, 255),
}

.facebook-button {
  height: 100%;
  float: right;
  background-color: transparent;
}
.facebook-button:hover {
  border-radius: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(255, 255, 255),
}

.youtube-button {
  height: 100%;
  float: right;
  background-color: transparent;
}
.youtube-button:hover {
  border-radius: 100%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(255, 255, 255),
}

/* Popup container - can be anything you want */
.email-wrapper {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.alert-popup {
  width: 25vw;
  visibility: hidden;
  background-color: #bb0d0d;
  color:  #f78a38;
  text-align: center;
  border-radius: 0;
  padding: 0;
  height: 4vh;
  position: absolute;
  justify-content: center;
  display: flex;
  z-index: 6;
  margin-left: 2.5vw;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

/* Popup arrow */
.alert-popup::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #bb0d0d transparent transparent transparent;
}


/* Toggle this class - hide and show the popup */
.show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .logo-box {
    margin-top: 5vh;
  }

  .logo-box .logo-wrapper {
    height: 12vh;
    width: 100vw;
  }

  .logo-box .logo {
    width: 100vw;
    height: 12vh;
    margin-top: 1vh;
  }

  .main-text-box {
    position: relative;
    height: 30vh;
    top: 5vh;
  }

  .main-text-box .dancing-gorilla {
    height: 40vh;
    width: 84vw;
    top: -8vh;
  }

  .main-text-box .main-text-wrapper {
    width: 100vw;
  }

  .main-text-box .main-text {
    font-size: 6vh;
    width: 100vw;
    margin-left: 8vw;
  }

  .main-text-box .sub-text-wrapper {
    width: 100vw;
    margin-top: 5%;
    justify-content: center;
    margin-right: 8vw;
  }

  .main-text-box .sub-text {
    font-size: 2.5vh;
    width: 100vw;
  }

  .waitlist-box {
    top: 7vh;
  }

  .waitlist-box .waitlist-button {
    width: 85vw;
    height: 10vh;
    font-size: 35px;
    -webkit-text-stroke: 2px #f78a38;
  }

  .social-media-badges-box {
    top: 12vh;
  }

  .social-media-badges-wrapper {
    height: 8vh;
    width: 75vw;
    margin-top: 0%;
  }

  .mail-icon-wrapper {
    margin-top: 4vh;
  }

  .mail-icon-wrapper .mail-icon {
    width: 20vw;
  }

  .popup {
    width: 70vw;
    height: 60vh;
  }

  .popup-wrapper {
    width: 55vw;
  }

  .popup-inner {
    position: relative;
    height: 60vh;
    width: 70vw;
    background-color: transparent;
  }

  .popup-inner .close-button {
    top: 0;
    font-size: 6vw;
    margin-right: 2vw;
    margin-top: 1vh;
  }

  .popup-wrapper .popup-sub-text {
    font-size: 4vw;
  }

  .popup-wrapper .popup-title-text {
    font-size: 7.5vw;
  }

  .email {
    width: 55vw;
    font-size: 3vh;
  }

  .subscribe-button {
    width: 55vw;
    font-size: 3vh;
    -webkit-text-stroke: 2px #f78a38;
  }

  .alert-popup {
    font-size: 2.5vw;
    height: 5vh;
    width: 40vw;
    top: -1vh;
  }

  .subscribed-text {
    font-size: 10vw;
    top: 15vh;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .logo-box .logo-wrapper {
    height: 10vh;
    width: 100vw;
  }

  .logo-box .logo {
    width: 100vw;
    height: 13vh;
  }

  .main-text-box {
    position: relative;
    height: 40vh;
    top: 5vh;
  }

  .main-text-box .dancing-gorilla {
    height: 100%;
    width: 83%;
    top: -2vh;
  }

  .main-text-box .main-text-wrapper {
    width: 100vw;
  }

  .main-text-box .main-text {
    font-size: 7vh;
    width: 100vw;
    margin-left: 8vw;
  }

  .main-text-box .sub-text-wrapper {
    width: 100vw;
    margin-top: 5%;
    justify-content: center;
    margin-right: 8vw;
  }

  .main-text-box .sub-text {
    font-size: 3vh;
    width: 100vw;
  }

  .waitlist-box {
    top: 5vh;
  }

  .waitlist-box .waitlist-button {
    width: 85vw;
    height: 10vh;
    font-size: 50px;
    -webkit-text-stroke: 3px #f78a38;
  }

  .social-media-badges-box {
    top: 10vh;
  }

  .social-media-badges-wrapper {
    height: 10vh;
    width: 75vw;
    margin-top: 0%;
  }

  .mail-icon-wrapper {
    margin-top: 3vh;
  }

  .mail-icon-wrapper .mail-icon {
    width: 20vw;
  }

  .popup {
    width: 70vw;
    height: 60vh;
  }

  .popup-wrapper {
    width: 60vw;
  }

  .popup-inner {
    position: relative;
    height: 60vh;
    width: 60vw;
    background-color: transparent;
  }

  .popup-inner .close-button {
    top: 0;
    font-size: 6vw;
    margin-right: -3vw;
    margin-top: 1vw;
  }

  .popup-wrapper .popup-sub-text {
    font-size: 3vh;
  }

  .popup-wrapper .popup-title-text {
    font-size: 8vw;
  }

  .email {
    width: 60vw;
  }

  .subscribe-button {
    width: 60vw;
  }

  .alert-popup {
    font-size: 2.5vw;
    height: 8vh;
    width: 40vw;
    top: -4vh;
  }

  .subscribed-text {
    font-size: 8vw;
    top: 15vh;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
  .logo-box {
    margin-top: 0;
  }

  .logo-box .logo-wrapper {
    height: 10vh;
    width: 100vw;
  }

  .logo-box .logo {
    width: 100vw;
    height: 20vh;
  }

  .main-text-box {
    position: relative;
    height: 40vh;
    top: 10vh;
  }

  .main-text-box .dancing-gorilla {
    height: 100%;
    width: 83%;
    top: -2vh;
  }

  .main-text-box .main-text-wrapper {
    width: 100vw;
  }

  .main-text-box .main-text {
    font-size: 6vh;
    width: 100vw;
    margin-left: 8vw;
    margin-top: 1%;
  }

  .main-text-box .main-text-wrapper {
    margin-top: 2%;
  }

  .main-text-box .sub-text-wrapper {
    width: 100vw;
    justify-content: center;
    margin-right: 8vw;
  }

  .main-text-box .sub-text {
    font-size: 4vh;
    width: 100vw;
  }

  .waitlist-box {
    top: 6vh;
  }

  .waitlist-box .waitlist-button {
    width: 85vw;
    height: 12vh;
    font-size: 60px;
    -webkit-text-stroke: 4px #f78a38;
  }

  .social-media-badges-box {
    top: 12vh;
  }

  .social-media-badges-wrapper {
    height: 12vh;
    width: 75vw;
    margin-top: 0%;
  }

  .popup {
    width: 50vw;
    height: 70vh;
  }

  .popup-wrapper {
    width: 40vw;
  }

  .popup-inner {
    width: 50vw;
    height: 70vh;
    margin-top: 5vh;
  }

  .popup-wrapper .popup-sub-text {
    font-size: 3vw;
  }

  .popup-wrapper .popup-title-text {
    font-size: 5.5vw;
  }

  .popup-inner .close-button {
    font-size: 4vw;
    margin-right: 1vw;
  }

  .mail-icon-wrapper .mail-icon {
    width: 14vw;
    margin-top: 1vh;
  }

  .email-wrapper {
    margin-top: 1vh;
  }

  .email {
    width: 40vw;
    height: 7vh;
  }

  .subscribe-button {
    width: 40vw;
    height: 7vh;
  }

  .alert-popup {
    font-size: 2vw;
    height: 8vh;
    width: 35vw;
    top: -4vh;
  }

  .subscribed-text {
    font-size: 8vw;
    top: 15vh;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
  .logo-box .logo-wrapper {
    height: 10vh;
    width: 100vw;
  }

  .logo-box .logo {
    width: 100vw;
    height: 20vh;
  }

  .main-text-box {
    position: relative;
    height: 40vh;
    top: 10vh;
  }

  .main-text-box .dancing-gorilla {
    height: 50vh;
    width: 50vw;
    top: -2vh;
  }

  .main-text-box .main-text-wrapper {
    width: 100vw;
  }

  .main-text-box .main-text {
    font-size: 10vh;
    width: 100vw;
    margin-left: 8vw;
    margin-top: 1%;
  }

  .main-text-box .main-text-wrapper {
    margin-top: 2%;
  }

  .main-text-box .sub-text-wrapper {
    width: 100vw;
    margin-top: 3%;
    justify-content: center;
    margin-right: 8vw;
  }

  .main-text-box .sub-text {
    font-size: 4vh;
    width: 100vw;
  }

  .waitlist-box {
    top: 18vh;
  }

  .waitlist-box .waitlist-button {
    width: 85vw;
    height: 12vh;
    font-size: 60px;
    -webkit-text-stroke: 4px #f78a38;
  }

  .social-media-badges-box {
    top: 20.5vh;
  }

  .social-media-badges-wrapper {
    height: 10vh;
    width: 75vw;
    margin-top: 1%;
  }

  .mail-icon-wrapper .mail-icon {
    width: 10vw;
  }

  .popup {
    width: 50vw;
  }

  .popup-inner {
    width: 50vw;
  }

  .popup-wrapper {
    width: 40vw;
  }

  .popup-inner .close-button {
    font-size: 3.5vw;
    margin-right: 1vw;
  }

  .email {
    width: 50vw;
  }

  .subscribe-button {
    width: 50vw;
  }

  .alert-popup {
    font-size: 1.5vw;
    height: 5vh;
    width: 35vw;
    top: -1vh;
  }

  .popup-wrapper .popup-title-text {
    font-size: 5vw;
  }

  .popup-wrapper .popup-sub-text {
    font-size: 2.2vw;
  }

  .subscribed-text {
    font-size: 6vw;
    top: 5vh;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .logo-box .logo-wrapper {
    height: 10vh;
    width: 100vw;
  }

  .logo-box .logo {
    width: 50vw;
    height: 20vh;
  }

  .main-text-box {
    position: relative;
    height: 40vh;
    top: 10vh;
  }

  .main-text-box .dancing-gorilla {
    height: 50vh;
    width: 42%;
    top: -5vh;
  }

  .main-text-box .main-text-wrapper {
    width: 42vw;
  }

  .main-text-box .main-text {
    font-size: 8vh;
    width: 40vw;
    margin-top: 1%;
  }

  .main-text-box .main-text-wrapper {
    margin-top: 2%;
  }

  .main-text-box .sub-text-wrapper {
    width: 50vw;
    margin-top: 5%;
    justify-content: center;
  }

  .main-text-box .sub-text {
    font-size: 4vh;
    width: 50vw;
  }

  .waitlist-box {
    top: 14vh;
  }

  .waitlist-box .waitlist-button {
    width: 42vw;
    height: 12vh;
    font-size: 60px;
    -webkit-text-stroke: 4px #f78a38;
  }

  .social-media-badges-box {
    top: 16vh;
  }

  .social-media-badges-wrapper {
    height: 10vh;
    width: 35vw;
  }

  .popup-wrapper .popup-title-text {
    font-size: 4vw;
  }

  .subscribed-text {
    font-size: 5vw;
    top: 12vh;
  }
}

.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1000;
  z-index: 3;
  position: fixed;
  background-color: transparent;
  display: none;
  margin-top: 0;
}
.confetti-piece {
  position: absolute;
  width: 1vw;
  height: 3vh;
  background: #ffd300;
  top: 0;
  opacity: 50%;
}
.confetti-piece:nth-child(1) {
  left: 7%;
  -webkit-transform: rotate(-40deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 182ms;
  -webkit-animation-duration: 1116ms;
}
.confetti-piece:nth-child(2) {
  left: 14%;
  -webkit-transform: rotate(4deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 161ms;
  -webkit-animation-duration: 1076ms;
}
.confetti-piece:nth-child(3) {
  left: 21%;
  -webkit-transform: rotate(-51deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 481ms;
  -webkit-animation-duration: 1103ms;
}
.confetti-piece:nth-child(4) {
  left: 28%;
  -webkit-transform: rotate(61deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 334ms;
  -webkit-animation-duration: 708ms;
}
.confetti-piece:nth-child(5) {
  left: 35%;
  -webkit-transform: rotate(-52deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 302ms;
  -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(6) {
  left: 42%;
  -webkit-transform: rotate(38deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 180ms;
  -webkit-animation-duration: 1168ms;
}
.confetti-piece:nth-child(7) {
  left: 49%;
  -webkit-transform: rotate(11deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 395ms;
  -webkit-animation-duration: 1200ms;
}
.confetti-piece:nth-child(8) {
  left: 56%;
  -webkit-transform: rotate(49deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 14ms;
  -webkit-animation-duration: 887ms;
}
.confetti-piece:nth-child(9) {
  left: 63%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 149ms;
  -webkit-animation-duration: 805ms;
}
.confetti-piece:nth-child(10) {
  left: 70%;
  -webkit-transform: rotate(10deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 351ms;
  -webkit-animation-duration: 1059ms;
}
.confetti-piece:nth-child(11) {
  left: 77%;
  -webkit-transform: rotate(4deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 307ms;
  -webkit-animation-duration: 1132ms;
}
.confetti-piece:nth-child(12) {
  left: 84%;
  -webkit-transform: rotate(42deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 464ms;
  -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(13) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(odd) {
  background: #7431e8;
}
.confetti-piece:nth-child(even) {
  z-index: 1;
}
.confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
  -webkit-animation-duration: 2000ms;
}
.confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
  -webkit-animation-duration: 2500ms;
  -webkit-animation-delay: 500ms;
}
.confetti-piece:nth-child(4n-7) {
  background: red;
}

@-webkit-keyframes makeItRain {
  from {opacity: 0;}
  50% {opacity: 1;}
  to {-webkit-transform: translateY(500px);}
}

@keyframes makeItRain {
  from {opacity: 0;}
  50% {opacity: 1;}
  to {-webkit-transform: translateY(500px);}
}
